import { AccountInfoRequest, AccountInfoResponse } from '@/types/accounts';
import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from '@tanstack/react-query';
import AccountAPI from '.';
import { AxiosError } from 'axios';

export const useAccount = (queryParams?: UseQueryOptions<AccountInfoResponse>) => {
  return useQuery<AccountInfoResponse>({
    ...queryParams,
    queryKey: ['account'],
    queryFn: async () => AccountAPI.getAccount(),
  });
};

export const useUpdateAccount = (
  queryParams?: UseMutationOptions<AccountInfoResponse, AxiosError, AccountInfoRequest>
) => {
  return useMutation<AccountInfoResponse, AxiosError, AccountInfoRequest>({
    ...queryParams,
    mutationFn: (data: AccountInfoRequest) => AccountAPI.updateAccount(data),
  });
};
