import { Box } from '@mui/material';

export const SellerMainLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      pt="24px"
      pb="48px"
      px={{
        mobile: '1rem',
        tablet: '24px',
        desktop: '2rem',
      }}
      display="flex"
      flexDirection="column"
      flex={1}
    >
      {children}
    </Box>
  );
};
