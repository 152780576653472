import {
  TableCell as FractalTableCell,
  TableCellProps as FractalTableCellProps,
  theme,
} from '@commercefoundation/fractal';

export type TableCellProps = FractalTableCellProps & {
  isTitle?: boolean;
};

export const TableCell = ({ isTitle, children, className, ...rest }: TableCellProps) => {
  return (
    <FractalTableCell
      sx={{
        '&.MuiTableCell-head': {
          backgroundColor: { mobile: 'transparent', tablet: theme.palette.gray['50'] },
          fontSize: '12px',
          fontWeight: 500,
          color: theme.palette.gray['700'],
          p: '10px 24px',
        },
        ...rest.sx,
      }}
      {...rest}
    >
      {children}
    </FractalTableCell>
  );
};
