import { SellerHeader } from '@/components/Layout';
import { SellerMainLayout } from '@/components/Layout/SellerMainLayout';
import { Button, TextArea, TextInput, theme, Typography } from '@commercefoundation/fractal';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useEffect, useState, useContext } from 'react';
import { BackButton } from '@/components/BackButton';
import Grid from '@mui/material/Unstable_Grid2';
import { TEditOrderTermsForm, TNewOrderTermsForm } from '@/types/orderTerms';
import { UnsavedChangesWarning } from '@/components/UnsavedChangesWarning';
import { FormProvider, Controller, useForm } from 'react-hook-form';
import { useCreateOrderTerm, useOrderTerm, useUpdateOrderTerm } from '@/api/orderterms';
import { queryClient } from '@/lib/react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Divider } from '@mui/material';
import { NotificationContext } from '@/providers/NotificationProvider';
import { PriceInputV2 } from '@/components/FormPriceInput';
import { EditOrderTermsSchema } from '../../schemas/EditOrderTermsSchema';
import { initializeInputPrice } from '@/helpers/stringHelper';

const EMPTY_ORDER_TERM_FORM: TEditOrderTermsForm = {
  id: '',
  name: '',
  description: '',
  minimum_order_value: '',
};

export const AddEditOrderTerms = () => {
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const { orderTermsId } = useParams() as { orderTermsId: string };
  const location = useLocation();

  const { showNotification } = useContext(NotificationContext);

  const handleCancel = () => {
    navigate('/ui/order-terms');
  };

  useEffect(() => {
    if (orderTermsId && location.pathname.includes('edit')) {
      setIsEdit(true);
    }
  }, [orderTermsId, location.pathname]);

  const { data: orderTerm } = useOrderTerm({
    id: orderTermsId,
  });

  const { mutate: createOrderTermsData, isLoading: isUpdatingOrderTerm } = useCreateOrderTerm({
    onSuccess: (data) => {
      const formData: TNewOrderTermsForm = {
        name: data.name,
        description: data.description,
        minimum_order_value: data.minimum_order_value,
      };
      methods.reset(formData, { keepDirty: false });
      navigate(`/ui/order-terms`);
      onSuccessNotification('Order terms created.');
      navigate('/ui/order-terms');
    },
    onError: () => {
      // methods.setValue('name', name, { shouldDirty: true });
      onErrorNotification('Something went wrong!');
    },
  });

  const onSuccessNotification = (message: string) => {
    showNotification({
      iconName: 'check-circle',
      variant: 'success',
      title: 'Success!',
      message: message,
    });
  };

  const onErrorNotification = (message: string) => {
    showNotification({
      iconName: 'alert-circle',
      variant: 'error',
      title: 'Error',
      message: message,
    });
  };

  const { mutate: updateOrderTerm } = useUpdateOrderTerm(orderTermsId, {
    onSettled: () => {
      queryClient.invalidateQueries(['order_terms', orderTermsId]);
    },
    onSuccess: () => {
      onSuccessNotification('Order terms updated successfully.');
    },
    onError: () => {
      onErrorNotification('Unable to update order terms!');
    },
  });

  const onSubmit = (data: TEditOrderTermsForm) => {
    const filteredData: TEditOrderTermsForm = {
      id: orderTermsId,
      name: data.name || '',
      description: data.description || '',
      minimum_order_value: data.minimum_order_value || '',
    };
    methods.reset(data, { keepDirty: false });

    if (isEdit) {
      updateOrderTerm(filteredData);
    } else {
      createOrderTermsData(filteredData);
    }
  };

  const methods = useForm<TEditOrderTermsForm>({
    defaultValues:
      {
        name: orderTerm?.name || '',
        description: orderTerm?.description || '',
        minimum_order_value: initializeInputPrice(orderTerm?.minimum_order_value) || '0.00',
      } ?? EMPTY_ORDER_TERM_FORM,
    resolver: yupResolver(EditOrderTermsSchema),
  });

  return (
    <Box position="relative" height="100vh">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <UnsavedChangesWarning isDirty={methods.formState.isDirty} />
          <SellerHeader
            title={`${isEdit ? 'Edit' : 'New'} Order Terms`}
            subTitle="Customize and assign unique order terms for each customer or customer segments."
            actions={[
              <Button
                key="cancel"
                variant="secondary"
                onClick={handleCancel}
                disabled={isUpdatingOrderTerm}
                sx={{ width: '80px' }}
              >
                Cancel
              </Button>,
              <Button
                key="save"
                variant="primary"
                type="submit"
                disabled={isUpdatingOrderTerm || !methods.formState.isDirty}
                sx={{ width: '80px' }}
              >
                Save
              </Button>,
            ]}
          />
          <SellerMainLayout>
            <Box display="flex" flexDirection="column" gap="24px">
              <Box display="flex" flexDirection="column">
                <BackButton label="Back to All Order Terms" href="/ui/order-terms" />
              </Box>
              <Grid
                container
                spacing="2rem"
                mobile={12}
                desktop={8}
                sx={{ borderTop: `1px solid ${theme.palette.gray['200']}`, px: '16px', pb: '8px' }}
              >
                <Grid mobile={12} desktop={5} paddingLeft="0">
                  <Box>
                    <Typography
                      fontSize="sm"
                      fontWeight="semibold"
                      color={theme.palette.gray['700']}
                    >
                      Name
                    </Typography>
                    <Typography fontSize="xs" color={theme.palette.gray['600']}>
                      {`It will appear when assigning order terms to a specific customer or customer
                      segments.`}
                    </Typography>
                  </Box>
                </Grid>
                <Grid mobile={12} desktop={7}>
                  <Box display="flex" flexDirection="column" gap="24px">
                    <Controller
                      name={'name'}
                      control={methods.control}
                      render={({ field }) => (
                        <TextInput
                          id="name"
                          name="name"
                          label="Name"
                          value={field.value}
                          // value={watch('name')}
                          onChange={field.onChange}
                          disabled={isEdit && field.value === 'default'}
                          sx={{ width: '100%' }}
                        />
                      )}
                    />

                    <Controller
                      name={'description'}
                      control={methods.control}
                      render={({ field }) => (
                        <TextArea
                          id="description"
                          value={field.value}
                          // value={watch('description')}
                          onChange={field.onChange}
                          label="Description (optional)"
                          sx={{ width: '100%' }}
                        />
                      )}
                    />
                  </Box>
                </Grid>

                <Divider
                  sx={{
                    width: '100%',
                    mt: '24px',
                    mb: '24px',
                  }}
                />

                <Grid mobile={12} desktop={5} paddingLeft="0">
                  <Box>
                    <Typography
                      fontSize="sm"
                      fontWeight="semibold"
                      color={theme.palette.gray['700']}
                    >
                      Minimum Order Value
                    </Typography>
                    <Typography fontSize="xs" color={theme.palette.gray['600']}>
                      {`Set the minimum order value required for this customer. The customer cannot 
                      place an order unless their cart meets or exceeds this value. `}
                    </Typography>
                  </Box>
                </Grid>

                <Grid mobile={12} desktop={7}>
                  <Box display="flex" flexDirection="column" gap="16px">
                    <Controller
                      name={'minimum_order_value'}
                      control={methods.control}
                      render={({ field, fieldState }) => (
                        <PriceInputV2
                          id="minimum_order_value"
                          name="minimum_order_value"
                          label="Minimum Order Value"
                          value={field.value.toString()}
                          onChange={field.onChange}
                          sx={{ width: '100%' }}
                          {...(fieldState.error?.message && {
                            errorMessage: fieldState.error.message,
                          })}
                        />
                      )}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </SellerMainLayout>
        </form>
      </FormProvider>
    </Box>
  );
};
