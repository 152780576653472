import { TextInput, Typography, theme, Icon } from '@commercefoundation/fractal';
import { Box, Collapse, styled } from '@mui/material';
import { useState } from 'react';

const StyledTextInput = styled(TextInput)`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

interface PriceRangeFilterProps {
  title: string;
  value?: { from: string; to: string };
  handleChange: (from: string, to: string) => void;
}

export const PriceRangeFilter: React.FC<PriceRangeFilterProps> = ({
  title,
  handleChange,
  value = { from: '', to: '' },
}) => {
  const [expanded, setExpanded] = useState<boolean>(true);

  const handleMinPriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let min = Number(event.target.value);
    if (min < 0) {
      min = 0;
    }
    handleChange(`${min}`, value.to);
  };

  const handleMaxPriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let max = Number(event.target.value);
    if (max < 0) {
      max = 0;
    }
    handleChange(value.from, `${max}`);
  };

  return (
    <Box my="8px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        component="button"
        onClick={() => setExpanded(!expanded)}
        width="100%"
        py="1rem"
      >
        <Typography fontSize="sm" fontWeight="medium" color={theme.palette.gray['700']}>
          {title}
        </Typography>
        {expanded ? (
          <Icon name="chevron-up" size="xs" color={theme.palette.gray['700']} />
        ) : (
          <Icon name="chevron-down" size="xs" color={theme.palette.gray['700']} />
        )}
      </Box>
      <Collapse in={expanded}>
        <Box sx={{ display: 'flex', gap: '12px', width: '100%' }}>
          <Box sx={{ flex: 1 }}>
            <StyledTextInput
              label="Min"
              type="number"
              value={value.from}
              onChange={handleMinPriceChange}
              id={'min'}
              name={'min'}
              leftIcon={<span>$</span>}
              inputProps={{ min: '0' }}
              sx={{
                flex: 1,
                flexWrap: 'nowrap',
                [`input:focus`]: {
                  boxShadow: 'none',
                },
              }}
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <StyledTextInput
              label="Max"
              type="number"
              value={value.to}
              onChange={handleMaxPriceChange}
              id={'max'}
              name={'max'}
              inputProps={{ min: '0' }}
              leftIcon={<span>$</span>}
              sx={{
                flexWrap: 'nowrap',
                [`input:focus`]: {
                  boxShadow: 'none',
                },
              }}
            />
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
};
