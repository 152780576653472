import { formatCurrencyString, formatNumber } from '@/helpers/stringHelper';
import { TextInput, TextInputProps } from '@commercefoundation/fractal';
import { ChangeEvent, useEffect } from 'react';
import { Control, Controller, ControllerRenderProps, SetFieldValue } from 'react-hook-form';

type FormPriceInputProps = Omit<TextInputProps, 'name'> & {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: SetFieldValue<any>;
  allowNegative?: boolean;
};

export const FormPriceInput = ({
  id,
  name,
  control,
  setValue,
  allowNegative = false,
  ...rest
}: FormPriceInputProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <PriceInput
            id={id}
            name={name}
            field={field}
            setValue={setValue}
            allowNegative={allowNegative}
            {...rest}
          />
        );
      }}
    />
  );
};

type PriceInputProps = TextInputProps & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  field: ControllerRenderProps<any, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: SetFieldValue<any>;
  allowNegative?: boolean;
};

export const PriceInput = ({
  id,
  name,
  field,
  setValue,
  allowNegative = false,
  ...rest
}: PriceInputProps) => {
  const handleBlur = () => {
    const formattedValue = formatNumber(field.value, 'default', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: false,
    });
    setValue(name, formattedValue);
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newValue = formatCurrencyString(event.target.value, allowNegative);
    if (newValue === null) return;
    setValue(name, newValue, { shouldDirty: true });
  };

  useEffect(() => {
    const formattedValue = formatNumber(field.value, 'default', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: false,
    });
    setValue(name, formattedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, setValue]);

  return (
    <TextInput
      id={id}
      name={name}
      value={field.value}
      onChange={handleOnChange}
      onBlur={handleBlur}
      startAdornment="$"
      inputMode="numeric"
      {...rest}
    />
  );
};

type PriceInputV2Props = Omit<TextInputProps, 'onChange' | 'value'> & {
  onChange: (value: string, shouldDirty?: boolean) => void;
  value: string;
};

export const PriceInputV2 = ({ id, name, value, onChange, ...rest }: PriceInputV2Props) => {
  const handleBlur = () => {
    const formattedValue = formatNumber(value, 'default', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: false,
    });
    onChange(formattedValue, false);
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newValue = formatCurrencyString(event.target.value);
    if (newValue === null) return;
    onChange(newValue, true);
  };

  useEffect(() => {
    const formattedValue = formatNumber(value, 'default', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: false,
    });
    onChange(formattedValue, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  return (
    <TextInput
      id={id}
      name={name}
      value={value}
      onChange={handleOnChange}
      onBlur={handleBlur}
      startAdornment="$"
      inputMode="numeric"
      {...rest}
    />
  );
};
