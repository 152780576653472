import { Link, useLocation } from 'react-router-dom';
import { Box, Collapse, Divider } from '@mui/material';
import foundationLogo from '@/assets/images/foundation_logo.svg';
import { Avatar, Icon, IconButton, Typography, theme } from '@commercefoundation/fractal';
import { useContext, useMemo, useState } from 'react';
import { useCurrentUser } from '@/features/auth';
import { getInitials } from '@/helpers/stringHelper';
import { NotificationContext } from '@/providers/NotificationProvider';
import { useSignout } from '@/features/auth';
import { SIDE_MENU_WIDTH, TOP_MENU_HEIGHT, TOP_MENU_HEIGHT_MOBILE } from '@/constants/layout';
import { useAccount } from '@/api/account/useAccount';

type MenuItemProps = {
  title: string;
  handle: string;
  href?: string;
  onClick?: () => void;
  icon?: string;
  children?: MenuItemProps[];
  selected?: boolean;
  className?: string;
  childMenu?: { title: string; href: string }[];
  expanded?: boolean;
  onExpand?: (handle: string) => void;
  rightIcon?: string;
};

const MenuItem = ({
  title,
  handle,
  icon,
  rightIcon,
  href,
  selected,
  childMenu,
  onClick,
  expanded,
  onExpand,
}: MenuItemProps) => {
  const { pathname: currentPath } = useLocation();
  const isHighlighted = useMemo(() => {
    if (handle === 'seller-portal') {
      return currentPath.startsWith('/ui/') && !currentPath.includes('/ui/admin');
    }
    if (!href) return false;
    return currentPath.startsWith(href);
  }, [currentPath, href, handle]);

  if (childMenu?.length) {
    return (
      <Box>
        <Box
          py="8px"
          px="12px"
          display="flex"
          flexDirection="row"
          bgcolor={selected ? theme.palette.primary['50'] : 'transparent'}
          borderRadius="6px"
          sx={{
            cursor: 'pointer',
            ':hover': { backgroundColor: theme.palette.primary['50'], boxShadow: theme.shadow.xs },
          }}
          justifyContent="space-between"
          alignItems="center"
          onClick={() => !!onExpand && onExpand(handle)}
        >
          <Box display="flex" flexDirection="row" gap="12px" alignItems="center">
            {icon && <Icon name={icon} size="xs" color={theme.palette.gray['500']} />}
            <Typography fontWeight="regular" color="gray.700" component="span">
              {title}
            </Typography>
          </Box>
          <Icon
            name={expanded ? 'chevron-up' : 'chevron-down'}
            size="xs"
            color={theme.palette.gray['500']}
          />
        </Box>
        <Collapse in={expanded === undefined ? false : expanded}>
          <Box display="flex" flexDirection="column" gap="8px" my="8px">
            {childMenu.map((item) => (
              <Link key={item.title} to={item.href} data-turbo="false" onClick={onClick}>
                <Box
                  py="8px"
                  px="12px"
                  display="flex"
                  flexDirection="row"
                  gap="12px"
                  bgcolor={
                    currentPath.startsWith(item.href) ? theme.palette.primary['50'] : 'transparent'
                  }
                  borderRadius="6px"
                  sx={{
                    ':hover': {
                      backgroundColor: theme.palette.primary['50'],
                      boxShadow: theme.shadow.xs,
                    },
                  }}
                >
                  <Typography
                    fontWeight={currentPath.startsWith(item.href) ? 'medium' : 'regular'}
                    color={
                      currentPath.startsWith(item.href)
                        ? theme.palette.primary['600']
                        : theme.palette.gray['700']
                    }
                    component="span"
                    sx={{ ml: '16px' }}
                  >
                    {item.title}
                  </Typography>
                </Box>
              </Link>
            ))}
          </Box>
        </Collapse>
      </Box>
    );
  }

  if (href) {
    return (
      <Link to={href} data-turbo="false">
        <Box
          py="8px"
          px="12px"
          display="flex"
          flexDirection="row"
          gap="12px"
          bgcolor={isHighlighted ? theme.palette.primary['50'] : 'transparent'}
          borderRadius="6px"
          sx={{
            ':hover': { backgroundColor: theme.palette.primary['50'], boxShadow: theme.shadow.xs },
          }}
          alignItems="center"
        >
          {icon && (
            <Icon
              name={icon}
              size="xs"
              color={isHighlighted ? theme.palette.primary['700'] : theme.palette.gray['500']}
            />
          )}
          <Typography
            fontWeight={isHighlighted ? 'medium' : 'regular'}
            color={isHighlighted ? theme.palette.primary['700'] : theme.palette.gray['700']}
            component="span"
          >
            {title}
          </Typography>
        </Box>
      </Link>
    );
  }

  return (
    <Box
      py="8px"
      px="12px"
      display="flex"
      flexDirection="row"
      onClick={onClick}
      bgcolor={'transparent'}
      borderRadius="6px"
      justifyContent={'space-between'}
      sx={{
        ':hover': { backgroundColor: theme.palette.primary['50'], boxShadow: theme.shadow.xs },
        cursor: 'pointer',
      }}
      alignItems="center"
    >
      <Box display="flex" flexDirection="row" gap="12px" alignItems="center">
        {icon && <Icon name={icon} size="xs" color={theme.palette.gray['500']} />}
        <Typography fontWeight={'regular'} color={theme.palette.gray['700']} component="span">
          {title}
        </Typography>
      </Box>
      {rightIcon && <Icon name={rightIcon} size="xs" color={theme.palette.gray['500']} />}
    </Box>
  );
};

type AccountMenuProps = {
  expanded: boolean;
  onExpand: (handle: string) => void;
};

const AccountMenu = ({ expanded, onExpand }: AccountMenuProps) => {
  const { data } = useCurrentUser();
  const { data: account } = useAccount();

  const fullName = data?.data?.full_name;
  const role = data?.data?.role;
  const knowledgeBaseUrl = 'https://help.getfoundation.com/';

  const initials = useMemo(() => {
    if (!fullName) return '';

    return getInitials(fullName, 2);
  }, [fullName]);

  const { showNotification } = useContext(NotificationContext);

  const { mutate: signout } = useSignout({
    config: {
      onSuccess() {
        window.location.assign('/');
      },
      onError() {
        showNotification({
          message: 'Something went wrong while logging out. Please try again.',
          variant: 'error',
          iconName: 'alert-circle',
        });
      },
    },
  });

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      zIndex={99}
      bgcolor={theme.palette.gray['50']}
      px="20px"
      pt="19px"
      pb="20px"
      borderTop={`1px solid ${theme.palette.gray['200']}`}
      position="absolute"
      width="100%"
      bottom={0}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{ cursor: 'pointer' }}
        onClick={() => onExpand('account')}
      >
        <Box display="flex" flexDirection="row" gap="12px" alignItems="center">
          <Avatar size="md" sx={{ bgcolor: theme.palette.primary['100'] }}>
            {initials}
          </Avatar>
          <Box display="flex" flexDirection="column">
            <Typography
              fontSize="sm"
              color={theme.palette.gray['700']}
              fontWeight="semibold"
              sx={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {fullName}
            </Typography>
            <Typography fontSize="sm" color={theme.palette.gray['600']}>
              Profile
            </Typography>
          </Box>
        </Box>
        <IconButton size="sm" variant="tertiary">
          <Icon name={expanded ? 'x-close' : 'chevron-selector-vertical'} size="xs" />
        </IconButton>
      </Box>
      <Collapse in={expanded}>
        <Box height="24px" />
        {role === 'isr' ? (
          <MenuItem title="Profile" href="/ui/admin/profile" icon="users-02" handle="profile" />
        ) : (
          <>
            <MenuItem
              title="Seller Portal"
              href={account?.show_welcome_page ? '/ui/welcome' : '/ui/dashboard'}
              icon="home-line"
              handle="seller-portal"
            />
            {(role === 'admin' || role === 'owner') && (
              <MenuItem title="Admin" href="/ui/admin" icon="users-02" handle="admin" />
            )}
            <MenuItem
              title="Help & Guides"
              onClick={() => window.open(knowledgeBaseUrl, '_blank')}
              icon="book-open-01"
              handle="help"
            />
          </>
        )}
        <MenuItem title="Log out" onClick={signout} icon="log-out-04" handle="log-out" />
      </Collapse>
    </Box>
  );
};

type Props = {
  homeLink: string;
  menuItems: MenuItemProps[];
  secondaryMenuTitle?: string;
  secondaryMenuItems?: MenuItemProps[];
};

export const SideMenu = ({
  homeLink,
  menuItems,
  secondaryMenuTitle,
  secondaryMenuItems,
}: Props) => {
  const [expandedMenuItem, setExpandedMenuItem] = useState<string | null>(null);

  const { data: currentUser } = useCurrentUser();
  const isIsr = currentUser?.data.is_isr;

  const storefrontUrl = currentUser?.data?.storefront_url;

  const handleVisitStore = () => {
    if (storefrontUrl) {
      window.open(storefrontUrl, '_blank');
    }
  };

  const handleExpand = (handle: string) => {
    if (expandedMenuItem === handle) {
      setExpandedMenuItem(null);
    } else {
      setExpandedMenuItem(handle);
    }
  };

  return (
    <Box
      display={{ mobile: 'none', tablet: 'block' }}
      borderRight={`1px solid ${theme.palette.gray['200']}`}
      bgcolor={theme.palette.gray['50']}
      minWidth={SIDE_MENU_WIDTH}
      height={'100%'}
      position="fixed"
      left={0}
      top={0}
      component="aside"
    >
      <Box
        display="flex"
        alignItems="center"
        px="20px"
        height={{
          mobile: TOP_MENU_HEIGHT_MOBILE,
          desktop: TOP_MENU_HEIGHT,
        }}
        borderBottom={`1px solid ${theme.palette.gray['200']}`}
      >
        <Box display="flex" width="140px" height={{ mobile: '16px', desktop: '22px' }}>
          <Link
            to={homeLink}
            data-turbo="false"
            className="flex items-center justify-center rounded text-decoration-none text-gray-800"
          >
            <img alt="foundation logo" src={foundationLogo} />
          </Link>
        </Box>
      </Box>
      <Box height={`calc(100vh - 162px)`} sx={{ overflowY: 'auto' }} pb="16px">
        <Box gap="8px" display="flex" flexDirection="column" p="1rem">
          {menuItems.map((item) => (
            <MenuItem
              {...item}
              key={item.handle}
              onExpand={handleExpand}
              expanded={item.handle === expandedMenuItem}
            />
          ))}
        </Box>
        {(!!secondaryMenuItems?.length || isIsr) && (
          <>
            <Divider sx={{ border: `0.5px solid ${theme.palette.gray['200']}` }} />
            <Box mt="24px" px="1rem" display="flex" flexDirection="column" gap="8px">
              {secondaryMenuTitle && (
                <Typography fontSize="xs" fontWeight="semibold" color={theme.palette.gray['500']}>
                  {secondaryMenuTitle}
                </Typography>
              )}
              {!isIsr && secondaryMenuItems && (
                <>
                  {secondaryMenuItems.map((item) => (
                    <MenuItem
                      {...item}
                      key={item.handle}
                      onExpand={handleExpand}
                      expanded={item.handle === expandedMenuItem}
                    />
                  ))}
                </>
              )}
              <MenuItem
                title="Visit Store"
                icon="globe-02"
                handle="visit-store"
                onClick={handleVisitStore}
                rightIcon="link-external-02"
              />
            </Box>
          </>
        )}
      </Box>
      <AccountMenu expanded={expandedMenuItem === 'account'} onExpand={handleExpand} />
    </Box>
  );
};
