import * as yup from 'yup';

export const EditOrderTermsSchema = yup.object().shape({
  name: yup.string().required('Please enter a name'),
  // description: yup.string().required('Description'),
  minimum_order_value: yup
    .string()
    .required('Please enter a minimum order value')
    .test('numberic', 'Please enter a valid number', (value) => {
      if (value) {
        return !isNaN(parseFloat(value));
      }
      return true;
    }),
});
