import { axios } from '@/lib/axios';
import type {
  IPostOrderTermPayload,
  IOrderTermData,
  IOrderTermsResponse,
  OrderTermsObj,
} from '@/types/orderTerms';
import {
  UseInfiniteQueryOptions,
  UseMutationOptions,
  UseQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import { TableOperationParams } from '@/types/tableOperation';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { AxiosError } from 'axios';

/* GET /api/backend/v1/order_terms */

type UseOrderTermOptions = {
  params?: TableOperationParams;
  queryProps?: QueryConfig<typeof getOrderTerms>;
};

export const useOrderTerms = ({ params, queryProps }: UseOrderTermOptions) => {
  return useQuery<ExtractFnReturnType<typeof getOrderTerms>, AxiosError>({
    ...queryProps,
    queryKey: ['order_terms', params],
    queryFn: () => getOrderTerms(params),
  });
};

type UseOrderTermsInfiniteQueryParams = {
  params?: TableOperationParams;
  queryProps?: UseInfiniteQueryOptions<IOrderTermsResponse>;
};

export const useOrderTermsInfiniteQuery = ({
  params,
  queryProps,
}: UseOrderTermsInfiniteQueryParams) => {
  return useInfiniteQuery<IOrderTermsResponse>({
    ...queryProps,
    queryKey: ['infinite', 'order_terms', params],
    queryFn: ({ pageParam }) => getOrderTerms({ ...params, page: pageParam }),
    getPreviousPageParam: (firstPage) => firstPage.pagination?.previous,
    getNextPageParam: (lastPage) => lastPage.pagination?.next,
  });
};

export const getOrderTerms = async (
  params?: TableOperationParams
): Promise<IOrderTermsResponse> => {
  return await axios.get(`/order_terms`, { params });
};

/* POST /api/backend/v1/order_terms */

export const useCreateOrderTerm = (
  queryProps?: UseMutationOptions<IOrderTermData, AxiosError, IPostOrderTermPayload>
) => {
  return useMutation<IOrderTermData, AxiosError, IPostOrderTermPayload>({
    mutationFn: (data: IPostOrderTermPayload) => {
      return axios.post('/order_terms', { order_term: data });
    },
    ...queryProps,
  });
};

export const createOrderTerm = async (
  data: IPostOrderTermPayload
): Promise<IPostOrderTermPayload> => {
  return await axios.post(`/order_terms`, { order_term: data });
};

/* PUT /api/backend/v1/order_terms/:id */

export const useUpdateOrderTerm = (
  id: string,
  queryProps?: UseMutationOptions<IOrderTermData, AxiosError, IPostOrderTermPayload>
) => {
  return useMutation<IOrderTermData, AxiosError, IPostOrderTermPayload>({
    mutationFn: (data: IPostOrderTermPayload) => {
      return updateOrderTerm(id, data);
    },
    ...queryProps,
  });
};

export const updateOrderTerm = async (
  id: string,
  data: IPostOrderTermPayload
): Promise<IOrderTermData> => {
  return await axios.put(`/order_terms/${id}`, { order_term: data });
};

/* DELETE /api/backend/v1/order_terms/:id */

export const useDeleteOrderTerm = (id: string, queryProps?: UseMutationOptions<string>) => {
  return useMutation({
    mutationFn: () => deleteOrderTerm(id),
    ...queryProps,
  });
};

export async function deleteOrderTerm(id: string): Promise<string> {
  return await axios.delete(`/order_terms/${id}`);
}

/* GET /api/backend/v1/order_terms/:id */

export const useOrderTerm = ({
  id,
  queryProps,
}: {
  id: string;
  queryProps?: UseQueryOptions<OrderTermsObj>;
}) => {
  return useQuery<OrderTermsObj>({
    queryKey: ['order_terms', id],
    queryFn: () => getOrderTerm(id),
    enabled: id !== undefined,
    ...queryProps,
  });
};

export async function getOrderTerm(id: string): Promise<OrderTermsObj> {
  return await axios.get(`order_terms/${id}`);
}
