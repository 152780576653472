import { Box } from '@mui/material';
import { Button, Icon } from '@commercefoundation/fractal';
import { Link, useLocation } from 'react-router-dom';
import { useMemo } from 'react';

type BackButtonProps = {
  label?: string;
  href?: string;
  disableMargin?: boolean;
  onClick?: () => void;
};

export const BackButton = ({ label, href, disableMargin = false, onClick }: BackButtonProps) => {
  const loaction = useLocation();
  const referrer: string | undefined = loaction.state?.referrer;

  const toLocation = useMemo(() => {
    if (href && referrer && referrer?.startsWith(href)) return referrer;
    if (href) return href;
    return -1;
  }, [href, referrer]);

  return (
    <Box
      display={{ mobile: 'none', tablet: 'flex' }}
      flexDirection="row"
      alignItems="flex-start"
      mb={disableMargin ? 0 : '24px'}
    >
      <Button
        variant="link-primary"
        startIcon={<Icon name="arrow-left" size="xs" />}
        {...(onClick
          ? {
              onClick: onClick,
            }
          : {
              LinkComponent: Link,
              to: toLocation,
            })}
      >
        {label ?? 'Back'}
      </Button>
    </Box>
  );
};
