import { useCallback, useEffect, useState } from 'react';
import { FeatureIcon, Modal, Typography, theme } from '@commercefoundation/fractal';
import { useBlocker } from 'react-router-dom';
import { Box } from '@mui/material';

type UnsavedChangesWarningProps = {
  isDirty: boolean;
};

export const UnsavedChangesWarning = ({ isDirty }: UnsavedChangesWarningProps) => {
  const blocker = useBlocker(isDirty);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const alertUser = useCallback(
    (e: BeforeUnloadEvent) => {
      if (isDirty) e.preventDefault();
    },
    [isDirty]
  );

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, [alertUser]);

  useEffect(() => {
    if (blocker.state === 'blocked') {
      setIsModalOpen(true);
    }
  }, [blocker, setIsModalOpen]);

  const handleStay = () => {
    setIsModalOpen(false);
    if (blocker.state === 'blocked' && blocker.reset) blocker.reset();
  };

  const handleLeave = () => {
    setIsModalOpen(false);
    if (blocker.proceed) blocker.proceed();
  };

  return (
    <Modal
      open={isModalOpen}
      header={{
        onClose: handleStay,
      }}
      footer={{
        actions: [
          {
            children: 'Back to page',
            variant: 'secondary',
            fullWidth: true,
            onClick: handleStay,
          },
          {
            children: 'Leave page',
            variant: 'primary-destructive',
            fullWidth: true,
            onClick: handleLeave,
          },
        ],
      }}
      size="sm"
    >
      <Box display="flex" flexDirection="column" gap="1rem">
        <Box>
          <FeatureIcon name="alert-circle" variant="outline-error" size="lg" />
        </Box>
        <Box display="flex" flexDirection="column" gap="4px">
          <Typography fontSize="lg" fontWeight="semibold" color={theme.palette.gray['900']}>
            Leave without saving?
          </Typography>
          <Typography fontSize="sm" color={theme.palette.gray['600']}>
            You have some unsaved data. Please save it, otherwise you may lose this information.
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
};
