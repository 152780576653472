import { Box, Select, SxProps } from '@mui/material';
import { Icon, theme } from '@commercefoundation/fractal';
import { TableCell, TableCellProps } from './TableCell';

export type SelectTableCellProps = Omit<TableCellProps, 'onChange'> & {
  value: string;
  onChange: (e: string) => void;
};

const menuProps = {
  sx: {
    border: `none`,
  },
  PaperProps: {
    sx: {
      border: `1px solid ${theme.palette.gray['200']}`,
      boxShadow: theme.shadow.lg,
      mt: '4px',
      py: '4px',
    },
  },
  MenuListProps: {
    sx: {
      padding: '0px',
    },
  },
};

const props: SxProps = {
  fontSize: '14px',
  fontWeight: '500',
  color: theme.palette.gray['700'],
};

export const SelectTableCell = ({ value, onChange, children, ...rest }: SelectTableCellProps) => {
  return (
    <TableCell {...rest}>
      <Box ml="1rem">
        <Select
          variant="standard"
          disableUnderline
          value={value}
          MenuProps={menuProps}
          inputProps={{
            sx: {
              '&:focus': {
                bgcolor: 'transparent',
              },
            },
          }}
          IconComponent={() => (
            <Icon
              name="chevron-down"
              size="xs"
              color={theme.palette.gray['700']}
              sx={{
                position: 'absolute !important',
                right: '0 !important',
                pointerEvents: 'none !important',
              }}
            />
          )}
          sx={props}
          onChange={(event) => onChange(event.target.value)}
        >
          {children}
        </Select>
      </Box>
    </TableCell>
  );
};
