import { axios } from '@/lib/axios';
import { AccountInfoRequest, AccountInfoResponse } from '@/types/accounts';

class AccountAPI {
  constructor() {
    throw new Error('Do not instantiate this class.');
  }

  static async getAccount(): Promise<AccountInfoResponse> {
    return await axios.get('/account');
  }

  static async updateAccount(data: AccountInfoRequest): Promise<AccountInfoResponse> {
    return await axios.put('/account', data);
  }
}

export default AccountAPI;
